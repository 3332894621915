<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense color="black" dark>
        <v-list-item>
          <v-list-item-action>
            <v-icon @click.stop="drawer = false">mdi-chevron-left</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <div class="d-flex align-items-center">

                <div class="text-center w-100">
                  <div>
                    <v-img
                        align="center"
                        :src="require('@/assets/logoifdc3.png')"
                        height="40"
                        width="40"
                        class="ml-auto mr-auto"
                    />
                  </div>
                  <div>Sendero Doril Delgado</div>
                  <div>IFDC SAO</div>
                  <!--                  <div>Oeste</div>-->
                </div>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.href"
        >
          <v-list-item-icon>
            <v-icon color="black">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <a class="text-decoration-none" href="https://forms.gle/cCcqn7cS9boJ1ZJy6">
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="black">mdi-cloud-question</v-icon>
          </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="">
                Cuesionario
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        </a>

      </v-list>
      <v-divider> </v-divider>
      <!--      <v-list>-->
      <!--        <v-list-item href="/logout">-->
      <!--          <v-list-item-icon>-->
      <!--            <v-icon color="black">mdi-logout</v-icon>-->
      <!--          </v-list-item-icon>-->
      <!--          <v-list-item-content>-->
      <!--            <v-list-item-title> Logout </v-list-item-title>-->
      <!--          </v-list-item-content>-->
      <!--        </v-list-item>-->
      <!--      </v-list>-->

      <!--  -->
    </v-navigation-drawer>

    <v-app-bar app color="black" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-img
            @click="inicio"
            :src="require('@/assets/logoifdc3.png')"
            max-height="50px"
            max-width="50px"
            class="mr-3"
        />
        <v-toolbar-title @click="inicio">Sendero Estación {{$route.params.nro}}</v-toolbar-title>

    </v-app-bar>

    <v-main>
      <!--  -->
      <v-container fluid class="main-container" >
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "MainLaout",
  props:{
    nro:[String,Number],
  },
  data: () => ({
    drawer: false,
    items: [
      // {
      //   title: "Cuestionario",
      //   href: "https://forms.gle/cCcqn7cS9boJ1ZJy6",
      //   icon: "mdi-shield-account",
      // },
      // {
      //   title: "Cursadas",
      //   href: "/docente/cursadas",
      //   icon: "mdi-shield-account",
      // },
      // {
      //   title: "Examenes",
      //   href: "/docente/examenes",
      //   icon: "mdi-shield-account",
      // },
    ],
  }),
  computed: {
    ...mapState(["estacion"]),
  },
  created() {
  },
  methods: {
    inicio(){
      this.$router.push('/inicio')
    }
  },
};
</script>

<style >


#app{
  /*background: url('../assets/madera.jpg') !important;*/
  /*background-size: cover !important;*/
  height: 100vh;
}


.theme--light.v-application {
  background: url('../assets/madera.jpg') !important;
  background-size: cover !important;
}

</style>
