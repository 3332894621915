<template>
    <div>
      <div
          class="inicio"
      >

        <div v-show="ifdc" class="foto">
          <v-img
              :src="require('@/assets/ifdc_logo.jpg')"
              class=""
          />
        </div>
        <div v-show="aeroclub" class="foto">
          <v-img
              :src="require('@/assets/logo_aeroclub.png')"
              class=""
          />
        </div>

      </div>
    </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Intro",
  methods:{
    ...mapActions(["getEstaciones"])
  },



  data(){
    return{
      ifdc:true,
      aeroclub:false,
    }
  },
  created() {
    this.getEstaciones();
    setTimeout(() => {
      this.ifdc = false;
      this.aeroclub = true;
      setTimeout(() => {
        this.aeroclub = false;
        this.$router.push({name:'inicio'})
      }, 3000)
    }, 3000)
  }
}
</script>

<style scoped>

.inicio{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.foto{

  padding: 3em;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

.foto img{
  width: 100%;
}

</style>
