<template>
  <div>
    <v-dialog v-model="inicioDialog" max-width="600px" @close="closeInicio=true">
      <v-card shaped light color="amber">
        <v-card-title>Comenzamos el recorrido!!</v-card-title>
        <v-card-text>
          En cada estación podran escuchar o leer la descripción de cada planta asi como acceder al glosario de la misma. Solo debes hacer click en los botones correspondientes
        </v-card-text>
        <v-card-actions>
          <v-btn color="brown" dark @click="closeInicio=true">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="item">
      <v-dialog v-model="dialog" max-width="600px" @close="$refs.textToSpeech.stop()">
        <v-card shaped color="amber" light>
          <v-card-title  class="roboto">{{item.acf.titulo}}</v-card-title>
          <v-card-text>
            {{item.acf.cientifico}}
          </v-card-text>
          <v-card-actions class="justify-end">
            <text-to-speech ref="textToSpeech" :text="item.acf.cientifico"></text-to-speech>
            <v-btn dark color="brown" @click="dialog=false" >Cerrar</v-btn>
          </v-card-actions>
        </v-card>


      </v-dialog>
    </div>

    <v-container  class="estacion-container" >

      <div v-if="item">

        <v-card dark class="mb-3" align="center">
          <v-card-title align="center" class="estacion-title justify-center amber--text text-uppercase" >
            <h5>{{item.acf.titulo}}</h5>
          </v-card-title>

        </v-card>


        <v-row>
          <v-col>
            <v-img v-if="item.acf.imagen" position="center"   class="grey darken-4" height="35vh" :src="item.acf.imagen.sizes.medium" />
          </v-col>
        </v-row>


        <v-row>
          <v-col>
            <v-btn class="roboto" x-large color="amber" @click="dialog=true" block>Descripción</v-btn>
          </v-col>
        </v-row>
      </div>


      <v-row>
        <v-col>
          <v-btn v-show="nro>1"  x-large class="roboto" color="amber" @click="decrement" block>Anterior</v-btn>
        </v-col>
        <v-col>
          <v-btn  x-large class="roboto" color="amber" @click="increment" block>Siguiente</v-btn>
        </v-col>
      </v-row>

    </v-container>

  </div>


</template>

<script>



import {mapState, mapActions, mapMutations} from 'vuex'
import TextToSpeech from "./TextToSpeech";

export default {
  name: "Estacion",
  components: {TextToSpeech},
  props:{
    nro:[String,Number]
  },
  data(){
    return{
      estacionesOrden:[
        {estacion:1,nombre:''},
        {estacion:'1a',nombre:'chilladora'},
        {estacion:2,nombre:''},
        {estacion:3,nombre:''},
        {estacion:4,nombre:''},
        {estacion:5,nombre:''},
        {estacion:6,nombre:''},
        {estacion:7,nombre:''},
        {estacion:8,nombre:''},
        {estacion:9,nombre:''},
        {estacion:10,nombre:''},
        {estacion:11,nombre:''},
        {estacion:12,nombre:''},
        {estacion:13,nombre:''},
        {estacion:14,nombre:''},
        {estacion:15,nombre:''},
        {estacion:16,nombre:''},
        {estacion:17,nombre:''},
        {estacion:18,nombre:''},
        {estacion:19,nombre:''},
        {estacion:20,nombre:''},
        {estacion:21,nombre:''},
        {estacion:22,nombre:''},
        {estacion:23,nombre:''},
        {estacion:24,nombre:''},
        {estacion:25,nombre:''},
        {estacion:26,nombre:''},
        {estacion:27,nombre:''},
        {estacion:28,nombre:''},
        {estacion:29,nombre:''},
        {estacion:30,nombre:''},
      ],
      dialog:false,
      dialogSpeech:false,
      closeInicio:false,
    }
  },
  watch:{
    dialog(){
      if(!this.dialog){
        this.$refs.textToSpeech.stop();
      }
    }
  },
  computed:{
    ...mapState(['estaciones','estacion']),
    item(){
      // return this.estacion;
      return this.estaciones.find(el=>el.acf.estacion == this.nro);
    },
    inicioDialog(){
      return this.nro==1 && !this.closeInicio
    }
  },
  methods:{
    ...mapMutations(["incrementEstacion","decrementEstacion","setEstacion"]),
    ...mapActions(["getEstaciones"]),
    increment(){
      if(this.nro == this.estacionesOrden[this.estacionesOrden.length-1].estacion){
        window.location.replace("https://forms.gle/cCcqn7cS9boJ1ZJy6");
        return;
      }
      var index = this.estacionesOrden.findIndex(el=>el.estacion==this.nro);
      console .log(index);

      var nro = this.estacionesOrden[index+1].estacion
          // this.nro==(this.estaciones.length)?1:
          //     +this.nro + 1
      ;
      this.$router.push('/estacion/'+nro);
      // this.estacion==(this.estaciones.length-1)?this.setEstacion(0):this.incrementEstacion();

    },
    decrement(){
      var nro = this.nro==1?(this.estaciones.length): +this.nro - 1;
      this.$router.push('/estacion/'+nro);
      // this.estacion==0?this.setEstacion(this.estaciones.length-1):this.decrementEstacion();
    }
  },
  async created() {
    console.log("creado");
    await this.getEstaciones();
    this.setEstacion(this.item);
    // this.estacion = this.nro;
  },

}
</script>

<style scoped>

.estacion-container {
  width: 90%;
  max-width: 500px;

}
.estacion-title {
  height: 96px !important;
}







</style>
