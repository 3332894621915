<template>
<v-container class="doril-container">

  <v-row>
    <v-col>
      <h1 class="amber--text text-center text-uppercase roboto my-title">Sendero Interpretativo Doril Delgado</h1>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-img
          contain
          max-height="50vh"
          :src="require('@/assets/doril.jpeg')"

          ></v-img>
    </v-col>
  </v-row>

  <v-dialog v-model="dialog" max-width="50vh" >
  <v-card dark color="" class="mt-5">
    <v-card-title></v-card-title>
    <v-card-text class="roboto bienvenida  text-left">
        Bienvenido!!! Lo invitamos a recorrer el sendero interpretativo "Doril Delgado" de flora de monte. Ud. encontrará diferentes estaciones con diferentes especies de flora nativa, las estaciones se encuentran numeradas y la especie que allí se encuentra se encuentra identificada con un cartel  al pie de la planta

    </v-card-text>
    <v-card-actions>
      <v-btn light color="amber" @click="dialog=false">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
  <v-row class="mt-3">
    <v-col>
      <v-btn class="roboto" x-large block @click="$router.push('/estacion/1')" color="amber">Comenzar Recorrido</v-btn>
    </v-col>
  </v-row>

</v-container>
</template>

<script>
export default {
  name: "InicioDoril",
  data(){
    return{
      dialog:true,
    }
  }
}
</script>

<style scoped>

.my-title{
  font-weight: 300;
}

.bienvenida{
  font-size: 1.3rem;
  font-weight: 300;
  text-align: left;
  line-height: 1.7rem;
}

.doril-container{
  width: 90%;
  max-width: 500px;
}

</style>
