<style scoped>
.icon {
  background: none;
  border: 0;
  outline-width: 0;
}
.v-btn{
  width: 100px;
}

</style>

<template>

  <div>
      <v-card-actions class="justify-center">
        <v-btn v-if="stopped" @click="greet">Escuchar</v-btn>
        <v-btn v-if="reproduciendo || pausado" @click="stop">Parar</v-btn>
        <v-btn v-if="reproduciendo && !pausado" @click="pause">Pausar</v-btn>
<!--        <v-btn v-if="reproduciendo" @click="stop">Parar</v-btn>-->
<!--        <v-btn v-if="reproduciendo" @click="pause">Pausa</v-btn>-->
        <v-btn v-if="pausado" @click="resume">Continuar</v-btn>
      </v-card-actions>
  </div>

</template>

<script type="text/javascript">



export default {
  props:{
    text:String,
  },
  data () {
    return {
      stopped:true,
      reproduciendo: false,
      pausado:false,
      isLoading: true,
      name: '',
      selectedVoice: 4,
      synth: window.speechSynthesis,
      voiceList: [],
      greetingSpeech: new window.SpeechSynthesisUtterance()
    }
  },



  mounted () {
    // wait for voices to load
    // I can't get FF to work without calling this first
    // Chrome works on the onvoiceschanged function
    this.voiceList = this.synth.getVoices()

    if (this.voiceList.length) {
      this.isLoading = false
    }

    this.synth.onvoiceschanged = () => {
      this.voiceList = this.synth.getVoices()
      // give a bit of delay to show loading screen
      // just for the sake of it, I suppose. Not the best reason
      setTimeout(() => {
        this.isLoading = false
      }, 800)
    }

    this.listenForSpeechEvents()
  },

  methods: {
    /**
     * React to speech events
     */
    listenForSpeechEvents () {
      this.greetingSpeech.onstart = () => {
        this.isLoading = true
      }

      this.greetingSpeech.onend = () => {
        this.isLoading = false
      }
    },

    /**
     * Shout at the user
     */
    greet () {
      this.stop();
      if(this.reproduciendo){
        this.reproduciendo = false;
        return;
      }
      // it should be 'craic', but it doesn't sound right
      this.greetingSpeech.text = this.text;

      // this.greetingSpeech.voice = this.voiceList[this.selectedVoice]
      this.greetingSpeech.lang = 'es-ES'

      this.synth.speak(this.greetingSpeech)
      this.reproduciendo = true;
      this.stopped = false;
    },

    stop(){
      this.synth.cancel();
      this.reproduciendo = false;
      this.stopped = true;
      this.pausado = false;
    },
    pause(){
      if(this.pausado){
        this.resume();
        return;
      }
      this.synth.pause();
      this.reproduciendo = false;
      this.pausado = true;
    },
    resume(){
      this.synth.resume();
      this.reproduciendo = true;
      this.pausado = false;
    }

  }


}
</script>
