import axios from "axios";


/** Default config for axios instance */
const API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? "https://wpsendero.ifdcsao.edu.ar"
    : "https://wpsendero.ifdcsao.edu.ar";
let config = {
  baseURL: `${API_ENDPOINT}/wp-json/wp/v2/`,
};


/** Creating the instance for axios */
const httpClient = axios.create(config);

/** Auth token interceptors */

// httpClient.interceptors.request.use((config) => {
//   // config.headers.Authorization = `Bearer ${authService.getToken()}`;
//
// });

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  (response) => {
    /** TODO: Add any response interceptors */
    return response;
  },
  (error) => {
    /** TODO: Do something with response error */
    return Promise.reject(error);
  }
  // loader.hide()
);

export { httpClient, API_ENDPOINT, config };
