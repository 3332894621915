import Vue from 'vue'
import Vuex from 'vuex'
import Estacion from "../api/Estacion";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    estaciones:[],
    estacion:null,
  },
  mutations: {
    setEstaciones(state,data){
      state.estaciones = data;
    },
    incrementEstacion(state){
      state.estacion++;
    },
    decrementEstacion(state){
      state.estacion--;
    },
    setEstacion(state,data){
      state.estacion = data;
    }
  },
  actions: {
    async getEstaciones({commit}){
      try {
        const { status, data } = await Estacion.all();
        if (status === 200) {
          commit("setEstaciones", data);
        }
      } catch (e) {
        console.log(e);
      }
    },

    setEstacion({commit},estacion){
      commit("setEstacion",estacion);
    },

    async getEstacion({commit},id){
      try {
        const { status, data } = await Estacion.view(id);
        if (status === 200) {
          commit("setEstaciones", data);
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  modules: {
  }
})
