import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from "../components/MainLayout";
import Estacion from "../components/Estacion";
import Intro from "../components/Intro";
import InicioDoril from "../components/InicioDoril";

Vue.use(VueRouter)

const routes = [

  {
    path: '/intro',
    name: 'intro',
    component:Intro
  },

  {
    path: '/',
    name: 'Default',
    component: MainLayout,
    redirect: 'intro',
    children:[
      {
        path: '/inicio',
        name: 'inicio',
        component:InicioDoril
      },
      {
        path: '/estacion/:nro',
        name: 'estaciones',
        component:Estacion,
        props:true,
      },
    ]

  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
